import { updateSetAccessor } from "typescript";
import {
  setCount,
  setDone,
  setIgnore,
  setSortBy,
} from "../store/features/searchSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import styles from "../styles";
//@ts-ignore
import { ReactComponent as CheckCircle } from "../assets/Icons/CheckCircle.svg";
import { store } from "../store/store";

const FilterHeaderBar = () => {
  const dispatch = useAppDispatch(),
    sortBy = useAppSelector((state) => state.search.sortBy);

  return (
    <div
      style={{
        display: "flex",
        // position: "relative",
        flexDirection: "column",
        borderBottom: "solid 1px",
        minWidth: "110vw",
      }}
    >
      <div style={styles.filterBar.container}>
        <CheckCircle style={{ fill: "white", height: 50 }} />
        <h1>Tasks </h1>
      </div>
      <div
        onClick={() => {
          // toggleShowCards(!showCards);
        }}
        style={{
          left: "20vw",
          display: "flex",
          alignItems: "center",
          flex: 1,
          padding: 10,
          justifyContent: "flex-start",
          textAlign: "center",
          marginTop: "7.5vh",
          fontWeight: 700,
          // background: "white",
        }}
      >
        <div
          onClick={() => {
            const sortBy = store.getState().search.sortBy;
            if (!sortBy || sortBy.by !== "done") {
              dispatch(setSortBy({ by: "done", asc: false }));
            } else {
              if (!sortBy.asc) {
                dispatch(setSortBy({ by: "done", asc: true }));
              } else {
                dispatch(setSortBy(null));
              }
            }
          }}
          style={{
            width: 30,
            cursor: "pointer",
            color: sortBy?.by === "done" ? "#00b4ec" : "black",
          }}
        >
          Done
        </div>
        <div
          style={{
            minWidth: 575,
            maxWidth: 575,
            overflow: "hidden",
            wordWrap: "break-word",
          }}
        >
          Text
        </div>
        <div
          onClick={() => {
            if (!sortBy || sortBy.by !== "count") {
              dispatch(setSortBy({ by: "count", asc: false }));
            } else {
              if (!sortBy.asc) {
                dispatch(setSortBy({ by: "count", asc: true }));
              } else {
                dispatch(setSortBy(null));
              }
            }
          }}
          style={{
            minWidth: 75,
            maxWidth: 75,
            cursor: "pointer",
            textAlign: "center",
            color: sortBy?.by === "count" ? "#00b4ec" : "black",
          }}
        >
          Count
        </div>
        <div
          onClick={() => {
            if (!sortBy || sortBy.by !== "done") {
              dispatch(setSortBy({ by: "done", asc: false }));
            } else {
              if (!sortBy.asc) {
                dispatch(setSortBy({ by: "done", asc: true }));
              } else {
                dispatch(setSortBy(null));
              }
            }
          }}
          style={{
            minWidth: 325,
            maxWidth: 325,
            cursor: "pointer",
            color: sortBy?.by === "done" ? "#00b4ec" : "black",
          }}
        >
          Done Info
        </div>
        <div
          style={{
            minWidth: 100,
            maxWidth: 100,
          }}
        >
          Product
        </div>
        <div
          style={{
            minWidth: 155,
            maxWidth: 155,
          }}
        >
          Department
        </div>
        <div
          style={{
            minWidth: 135,
            maxWidth: 135,
            cursor: "pointer",
            color: sortBy?.by === "created" ? "#00b4ec" : "black",
          }}
          onClick={() => {
            const sortBy = store.getState().search.sortBy;
            if (!sortBy || sortBy.by !== "created") {
              dispatch(setSortBy({ by: "created", asc: false }));
            } else {
              if (!sortBy.asc) {
                dispatch(setSortBy({ by: "created", asc: true }));
              } else {
                dispatch(setSortBy(null));
              }
            }
          }}
        >
          Created
        </div>
        <div
          style={{
            minWidth: 135,
            maxWidth: 135,
            cursor: "pointer",
          }}
        >
          Changed
        </div>
        <div
          style={{
            minWidth: 165,
            maxWidth: 165,
            cursor: "pointer",
          }}
        >
          jaeger
        </div>
        <div
          style={{
            minWidth: 150,
            maxWidth: 150,
            cursor: "pointer",
          }}
        >
          Service
        </div>
        <div
          style={{
            minWidth:160,
            maxWidth:160,
            cursor: "pointer",
          }}
        >
          Command
        </div>
      </div>
    </div>
  );
};
export default FilterHeaderBar;
