import { useNavigate } from "react-router-dom";
import {
  setCount,
  setDone,
  setIgnore,
  setSortBy,
  setUseFilters,
} from "../store/features/searchSlice";
import { setApiKey } from "../store/features/userSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { store } from "../store/store";
import styles from "../styles";
//@ts-ignore
import { ReactComponent as LogoutIcon } from "../assets/Icons/PowerButton.svg";
//@ts-ignore
import { ReactComponent as CheckCircle } from "../assets/Icons/CheckCircle.svg";

const Sidebar = () => {
  const navigate = useNavigate(),
    dispatch = useAppDispatch(),
    done = useAppSelector((state) => state.search.done),
    count = useAppSelector((state) => state.search.count),
    useFilters = useAppSelector((state) => state.search.useFilters),
    sortBy = useAppSelector((state) => state.search.sortBy),
    ignore = useAppSelector((state) => state.search.ignore);

  return (
    <div style={styles.sideBarContainer}>
      <h1
        style={{
          marginLeft: 15,
          fontSize: 50,
          textAlign: "center",
          marginTop: 25,
          marginBottom: 50,
        }}
      >
        {" "}
        msTasks{" "}
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={styles.sidebarButton}>
          <CheckCircle
            style={{ width: 25, height: 25, marginLeft: 5, marginRight: 5 }}
            fill="#00AEF0"
          />
          <div>Dashboard </div>
        </div>
        <div
          style={{
            marginTop: 50,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...styles.filterBar.inputContainer,
              marginBottom: 10,
              width: 125,
            }}
          >
            <h2 style={{ alignSelf: "center" }}> Filters: </h2>
            <input
              checked={useFilters}
              onChange={(e) => {
                dispatch(setUseFilters(e.target.checked));
              }}
              type="checkbox"
            />
          </div>
          <div style={styles.filterBar.inputContainer}>
            <h3> Count </h3>
            <input
              style={{ width: 30 }}
              value={count}
              onChange={(e) => {
                dispatch(setCount(Number(e.target.value)));
              }}
              type="number"
            />
          </div>
          <div style={styles.filterBar.inputContainer}>
            <h3> Done </h3>
            <input
              checked={done}
              onChange={(e) => {
                dispatch(setDone(e.target.checked));
              }}
              type="checkbox"
            />
          </div>
          <div style={styles.filterBar.inputContainer}>
            <h3> ignored </h3>
            <input
              checked={ignore}
              onChange={(e) => {
                dispatch(setIgnore(e.target.checked));
              }}
              type="checkbox"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Sort by</h2>
        <div>
          <div style={styles.filterBar.sortByContainer}>
            <h3> count </h3>
            <div>
              <label>
                asc.
                <input
                  checked={sortBy?.asc && sortBy?.by === "count"}
                  onChange={(e) => {
                    if (sortBy?.asc && sortBy?.by === "count") {
                      dispatch(setSortBy(null));
                    } else {
                      dispatch(setSortBy({ by: "count", asc: true }));
                    }
                  }}
                  type="checkbox"
                />
              </label>
              <label>
                desc.
                <input
                  checked={!sortBy?.asc && sortBy?.by === "count"}
                  onChange={(e) => {
                    if (!sortBy?.asc && sortBy?.by === "count") {
                      dispatch(setSortBy(null));
                    } else {
                      dispatch(setSortBy({ by: "count", asc: false }));
                    }
                  }}
                  type="checkbox"
                />
              </label>
            </div>
          </div>

          <div style={styles.filterBar.sortByContainer}>
            <h3> created </h3>
            <div>
              <label>
                asc.
                <input
                  checked={sortBy?.asc && sortBy?.by === "created"}
                  onChange={(e) => {
                    if (sortBy?.asc && sortBy?.by === "created") {
                      dispatch(setSortBy(null));
                    } else {
                      dispatch(setSortBy({ by: "created", asc: true }));
                    }
                  }}
                  type="checkbox"
                />
              </label>
              <label>
                desc.
                <input
                  checked={!sortBy?.asc && sortBy?.by === "created"}
                  onChange={(e) => {
                    if (!sortBy?.asc && sortBy?.by === "created") {
                      dispatch(setSortBy(null));
                    } else {
                      dispatch(setSortBy({ by: "created", asc: false }));
                    }
                  }}
                  type="checkbox"
                />
              </label>
            </div>
          </div>
          <div style={styles.filterBar.sortByContainer}>
            <h3> done </h3>
            <div>
              <label>
                asc.
                <input
                  checked={sortBy?.asc && sortBy?.by === "done"}
                  onChange={(e) => {
                    if (sortBy?.asc && sortBy?.by === "done") {
                      dispatch(setSortBy(null));
                    } else {
                      dispatch(setSortBy({ by: "done", asc: true }));
                    }
                  }}
                  type="checkbox"
                />
              </label>
              <label>
                desc.
                <input
                  checked={!sortBy?.asc && sortBy?.by === "done"}
                  onChange={(e) => {
                    if (!sortBy?.asc && sortBy?.by === "done") {
                      dispatch(setSortBy(null));
                    } else {
                      dispatch(setSortBy({ by: "done", asc: false }));
                    }
                  }}
                  type="checkbox"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "auto",
          color: "white",
          marginBottom: 35,
          textAlign: "center",
        }}
      >
        <div
          onClick={() => {
            store.dispatch(setApiKey(""));
            navigate("/login");
          }}
          style={{
            cursor: "pointer",
            fontSize: 22,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <LogoutIcon style={{ fill: "white", height: 35, width: 35 }} />
          <div>Logout</div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
