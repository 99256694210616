import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setApiKey } from "../../store/features/userSlice";
import { useAppDispatch } from "../../store/hooks";
import { store } from "../../store/store";
import styles from "../../styles";

const LoginPage = () => {
  const navigate = useNavigate(),
    dispatch = useAppDispatch(),
    baseUrl = store.getState().user.baseUrl;
  const [loginError, setLoginError] = useState(false);

  const sendLoginData = async (e: React.FormEvent<HTMLFormElement>) => {
    console.error("SUBMITTING LOGIN FORM");
    e.preventDefault();

    //@ts-ignore
    let formData = new FormData(e.target);
    fetch(baseUrl + "/login", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: formData.get("user"),
        password: formData.get("password"),
      }),
    }).then((response) => {
      response.json().then((jsonResp) => {
        if (jsonResp.errorCode === 0) {
     
          navigate("/");
          dispatch(setApiKey(jsonResp.apiKey));
      
        } else {
          console.error(jsonResp);
          setLoginError(true);
        }
      }).catch((e)=>{
      setLoginError(true);
    
      console.error("couldnt authenticate")});
    });
  };

  return (
    <div style={styles.appContainer}>
      <form
        onSubmit={(e) => {
          sendLoginData(e);
        }}
      >
        <img
          style={{
            zIndex: -2,
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
          }}
          src={require("../../assets/Icons/ApoOneBackground.png")}
        />
        <div style={styles.loginPage.container}>
          <h2 style={{ color: "white", fontSize: 30 }}> msTask Dashboard</h2>
          <div style={styles.loginPage.backgroundDiv}> </div>
          <input
            name="user"
            placeholder="Username"
            style={{
              ...styles.loginPage.textInput,
              color: loginError ? "red" : undefined,
              border: loginError? "red solid 2px ": undefined,
            }}
            type="text"
            onChange={() => {
              if (loginError) {
                setLoginError(false);
              }
            }}
          />
          <input
            style={{
              ...styles.loginPage.textInput,
              color: loginError ? "red" : undefined,
              outline: loginError? "red solid 2px ": undefined,
            }}
            placeholder="Passwort"
            name="password"
            type="password"
            onChange={() => {
              if (loginError) {
                setLoginError(false);
              }
            }}
          />
          <input
            style={styles.loginPage.submitButton}
            value="Login"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
export default LoginPage;
