import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "../store/hooks";

//@ts-ignore
import { ReactComponent as CancelCross } from "../assets/Icons/CancelIcon.svg";

//@ts-ignore
import { ReactComponent as SuccessIcon } from "../assets/Icons/CheckCircle.svg";
import { setModalTask, toggleModalShowing } from "../store/features/TransactionModal";
import {store} from "../store/store";

const TransActionModal = (props: any) => {
  const navigate = useNavigate(),
    dispatch = useAppDispatch(),
    message = useAppSelector((state) => state.transactionModal.message),
    success = useAppSelector((state) => state.transactionModal.success),
    showingModal = useAppSelector((state) => state.transactionModal.showing);

  console.error(showingModal, "showingModal");
  if (!showingModal) return null;
  return (
    <div
      onClick={() => {
        dispatch(toggleModalShowing());
	dispatch(setModalTask(null));
      }}
      style={{
        position: "absolute",
        whiteSpace: "pre-wrap",
        display: "flex",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          opacity: 0.5,
          background: "white",
        }}
      />
      <div
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-evenly",
          opacity: 1,
          background: "white",
          zIndex: 2,
          maxHeight: 300,
          height: 400,
          minWidth: 600,
          borderRadius: 5,
          boxShadow: "10px 15px 25px #e2e2e2",
          padding: 20,
        }}
      >
        {!success && <CancelCross style={{ fill: "#ff5f5f" }} />}
        {success && <SuccessIcon style={{ fill: "#76bd22" }} />}
        <h1
          style={{
            fontSize: 30,
            whiteSpace: "break-spaces",
	    wordBreak: "break-word",
            maxWidth: "100%",
          }}
        >
          {message}
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 50,
          }}
        >
          <button
	    style={{width: 85}}
            onClick={(e) => {
	    e.stopPropagation()
              dispatch(toggleModalShowing);
            }}
          >
            {" "}
            No
          </button>
          <button
	    style={{width: 85}}
            onClick={(e) => {
	    e.stopPropagation()
	      const onAccept = store.getState().transactionModal.onAccept 
	      const onAcceptAction = store.getState().transactionModal.actionOnAccept
	      if( onAcceptAction === "done" ){
		onAccept.markAsDone()
	      }else if (onAcceptAction ==="ignore" ){
		onAccept.markAsIgnored()
	      }
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
export default TransActionModal;
