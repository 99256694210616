// import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./components/pages/LandingPage";
import LoginPage from "./components/pages/LoginPage";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
	<LandingPage />
        }
      />
      <Route
        path="/login"
        element={
          <div className="App">
              <LoginPage />
          </div>
        }
      />
    </Routes>
  );
}

export default App;
