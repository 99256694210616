import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "./features/searchSlice";
import taskSlice from "./features/taskSlice";
import TransactionModal from "./features/TransactionModal";
import userSlice from "./features/userSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    tasks: taskSlice,
    search: searchSlice,
    transactionModal: TransactionModal,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["transactionModal.onAccept"],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
