import react from "react";
const colorCoding = require("../colorCoding.json");

const OwnershipTag = (props: { cardOwner: string }) => {
  if (!props.cardOwner) return null;
  return (
    <div
      style={{
        background: colorCoding[props.cardOwner]
          ? colorCoding[props.cardOwner]
          : "#FF5f5F",
        borderRadius: 18,
        fontSize: 14,
        margin: 5,
        fontWeight: 400,
        color: "white",
        padding: 7.5,
	textAlign: "center",
      }}
    >
      {" "}
      {props.cardOwner}{" "}
    </div>
  );
};
export default OwnershipTag;
