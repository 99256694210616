import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Task {
  changed: Date;
  count: number;
  created: Date;
  data: string;
  department: string;
  done: {
    by: string;
    date: string;
  };
  serviceName?: string;
  command: string;
  jaegerTraceId: string;
  key: string;
  product: string;
  referenceId: string;
  taskId: number;
  text: string;
  ignore: boolean;
}

interface TaskState {
  tasks: Task[];
}
// Define the initial state using that type
const initialState: TaskState = {
  tasks: [],
};

export const taskSlice = createSlice({
  name: "search",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<Task[]>) => {
      state.tasks = action.payload;
    },
  },
});

export const { setTasks } = taskSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.value

export default taskSlice.reducer;
