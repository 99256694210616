import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../store";

// Define a type for the slice state
export interface searchState {
  count?: number;
  done?: boolean;
  ignore?: boolean;
  useFilters: boolean;
  sortBy?: {
    by: "count" | "done" | "created";
    asc: boolean;
  } | null;
}

// Define the initial state using that type
const initialState: searchState = {
  count: 0,
  ignore: false,
  done: false,
  useFilters: true,
};

export const searchSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number | undefined>) => {
      state.count = action.payload;
    },
    setDone: (state, action: PayloadAction<boolean>) => {
      state.done = action.payload;
    },
    setIgnore: (state, action: PayloadAction<boolean>) => {
      state.ignore = action.payload;
    },
    setUseFilters: (state, action: PayloadAction<boolean>) => {
      state.useFilters = action.payload;
    },
    setSortBy: (
      state,
      action: PayloadAction<{
        by: "count" | "done" | "created";
        asc: boolean;
      } | null>
    ) => {
      if (!action.payload) {
        state.sortBy = null;
      } else {
        state.sortBy = {
          by: action.payload.by,
          asc: action.payload.asc,
        };
      }
    },
  },
});

export const { setDone, setUseFilters, setCount, setIgnore, setSortBy } =
  searchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.value

export default searchSlice.reducer;
