import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setTasks, Task } from "../../store/features/taskSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { store } from "../../store/store";
import styles from "../../styles";
import FilterHeaderBar from "../FilterHeaderBar";
import Sidebar from "../Sidebar";
import CardTaskComponent from "../CardTaskComponent";
import ListTaskComponent from "../ListTaskComponent";
import TransActionModal from "../TransactionModal";
import SingleTaskModal from "../SingleTaskModal";

const LandingPage = () => {
  const apiKey = useAppSelector((state) => state.user.apiKey),
    tasks = useAppSelector((state) => state.tasks.tasks),
    useFilters = useAppSelector((state) => state.search.useFilters),
    done = useAppSelector((state) => state.search.done),
    count = useAppSelector((state) => state.search.count),
    ignore = useAppSelector((state) => state.search.ignore),
    [showCards, toggleShowCards] = useState(false),
    showingModal = useAppSelector((state) => state.transactionModal.showing),
    modalTask = useAppSelector((state) => state.transactionModal.modalTask),
    sortBy = useAppSelector((state) => state.search.sortBy),
    baseUrl = store.getState().user.baseUrl,
    navigate = useNavigate(),
    dispatch = useAppDispatch();

  useEffect(() => {
    if (!apiKey) {
      navigate("/login");
    } else {
      getTasks();
    }
  }, [useFilters, done, count, ignore]);

  const getTasks = async () => {
    if (!apiKey) return;
    const url = baseUrl + "/task/getAll";
    const bodyParams = {
      filter: {
        done: done,
        count: count,
        ignore: ignore,
      },
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: apiKey,
      },
      body: useFilters ? JSON.stringify(bodyParams) : undefined,
    })
      .then((response) => {
        response.json().then((respJson) => {
          console.log("taskList", respJson);
	  if(respJson.errorCode === 0 ){
          const tasks = respJson.tasks;
          dispatch(setTasks(tasks));
	  }else{
	  navigate("/login")
	  }
        });
      })
      .catch((e) => {
        console.info("err", e);
      });
  };
  return (
    <div style={styles.appContainer}>
      {showingModal && <TransActionModal />}
      {!showingModal && modalTask && (
        <div>
          <SingleTaskModal />
        </div>
      )}
      <Sidebar />
      <div style={{...styles.bodyContainer,  }}>
        <FilterHeaderBar />
        <div
          style={
            showCards
              ? styles.landingPage.cardContainer
              : styles.landingPage.listContainer
          }
        >
          {tasks && sortBy
            ? [...tasks]
                .sort((a, b) => {
                  if (sortBy.by == "count") {
                    if (sortBy.asc) {
                      return a.count - b.count;
                    } else {
                      return b.count - a.count;
                    }
                  } else if (sortBy.by == "created") {
                    if (sortBy.asc) {
                      return (
                        new Date(a.created).valueOf() -
                        new Date(b.created).valueOf()
                      );
                    } else {
                      return (
                        new Date(b.created).valueOf() -
                        new Date(a.created).valueOf()
                      );
                    }
                  } else if (sortBy.by === "done") {
                    if (sortBy.asc) {
                      if (!a.done) return -1;
                      if (!b.done) return 1;

                      return (
                        new Date(a.done.date).valueOf() -
                        new Date(b.done.date).valueOf()
                      );
                    } else {
                      if (!a.done) return 1;
                      if (!b.done) return -1;

                      return (
                        new Date(b.done.date).valueOf() -
                        new Date(a.done.date).valueOf()
                      );
                    }
                  }
                  return 1;
                })
                .map((task: Task) => {
                  if (showCards) {
                    return <CardTaskComponent task={task} />;
                  } else {
                    return <ListTaskComponent task={task} />;
                  }
                })
            : tasks.map((task: Task) => {
                if (showCards) {
                  return <CardTaskComponent task={task} />;
                } else {
                  return <ListTaskComponent task={task} />;
                }
              })}
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
