import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "../store/hooks";

//@ts-ignore
import { ReactComponent as CancelCross } from "../assets/Icons/CancelIcon.svg";

//@ts-ignore
import { ReactComponent as SuccessIcon } from "../assets/Icons/CheckCircle.svg";
import { setModalTask, toggleModalShowing } from "../store/features/TransactionModal";
import {store} from "../store/store";
import CardTaskComponent from "./CardTaskComponent";

const SingleTaskModal = (props: any) => {
  const navigate = useNavigate(),
    dispatch = useAppDispatch(),
    message = useAppSelector((state) => state.transactionModal.message),
    success = useAppSelector((state) => state.transactionModal.success),
    task =  useAppSelector((state) => state.transactionModal.modalTask),
    showingModal = useAppSelector((state) => state.transactionModal.showing);

  console.error(showingModal, "showingModal");
  
  if(!task) return null
  return (
    <div
      onClick={() => {
        dispatch(setModalTask(null));
      }}
      style={{
        position: "absolute",
        whiteSpace: "pre-wrap",
        display: "flex",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          opacity: 0.5,
          background: "white",
        }}
      />
      <div
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-evenly",
          opacity: 1,
          background: "white",
          zIndex: 2,
          maxHeight: 300,
          height: 400,
          minWidth: 600,
          borderRadius: 5,
          boxShadow: "10px 15px 25px #e2e2e2",
          padding: "50px 75px",
	  
        }}
	onClick={(e)=>{
		e.stopPropagation()
	}}
      >
	<CardTaskComponent task={task} />
      </div>
    </div>
  );

};
export default SingleTaskModal;
