import { Task } from "../store/features/taskSlice";
import {
  setActionOnAccept,
  setModalMessage,
  setModalTask,
  toggleModalShowing,
} from "../store/features/TransactionModal";
import { useAppDispatch } from "../store/hooks";
import { store } from "../store/store";
const CardTaskComponent = (props: { task: Task }) => {
  const dispatch = useAppDispatch();
  const markAsDone = () => {
    dispatch(toggleModalShowing());
    dispatch(setModalTask(props.task));
    dispatch(
      setModalMessage(`
	Do you really want to mark
	   "${props.task.referenceId}"
	as ${props.task.done ? "NOT" : ""} done ? `)
    );
    dispatch(setActionOnAccept("done"));
  };
  const markAsIgnored = () => {
    dispatch(toggleModalShowing());
    dispatch(setModalTask(props.task));
    dispatch(
      setModalMessage(`
	Do you really want to mark
	   "${props.task.referenceId}"
	as ${props.task.ignore ? "NOT" : ""} ignored ? `)
    );
    dispatch(setActionOnAccept("ignore"));
  };

  return (
    <div
      style={{
        width: 500,
        display: "flex",
        flexDirection: "column",
        margin: 5,
        alignItems: "center",
        borderStyle: "solid",
        padding: 20,
        borderRadius: 15,
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          marginBottom: 15,
        }}
      >
        <div style={{ display: "flex", alignSelf: "flex-start" }}>
          <h1 style={{ margin: 0 }}>{props.task.taskId}</h1>
          {props.task.done ? (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginLeft: 15,
                padding: 5,
                fontWeight: 800,
                color: "green",
              }}
            >
              {" "}
              DONE{" "}
            </div>
          ) : (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginLeft: 15,
                padding: 5,
                fontWeight: 800,
                color: "red",
              }}
            >
              {" "}
              Pending{" "}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifySelf: "flex-end",
            flexDirection: "column",
            // background:"lightblue",
          }}
        >
          <div
            style={{
              textAlign: "right",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {" "}
            Count:<h3 style={{ flex: 4 }}> {props.task.count}</h3>
          </div>
          <div
            style={{
              gap: 10,
              textAlign: "right",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Dpt: <div> {props.task.department}</div>
          </div>
        </div>
      </div>

      <h4
        style={{
          margin: 0,
          overflowWrap: "break-word",
          width: "100%",
          alignSelf: "flex-start",
          fontSize: 25,
        }}
      >
        {props.task.text}
      </h4>
      <div style={{ display: "flex", width: "90%", gap: 20 }}>
        <div style={{ width: 110 }}>taskId: </div>
        <div>{props.task.taskId}</div>
      </div>
      <div
        style={{
          display: "flex",
          overflowX: "hidden",
          wordWrap: "break-word",
          width: "90%",
          gap: 50,
        }}
      >
        <div style={{ width: 110 }}>referenceId: </div>
        <div>{props.task.referenceId}</div>
      </div>
      <div style={{ display: "flex", width: "90%", gap: 20 }}>
        <div style={{ width: 110 }}>product: </div>
        <div>{props.task.product}</div>
      </div>
      <div style={{ display: "flex", width: "90%", gap: 20 }}>
        <div style={{ width: 110 }}>created: </div>
        <div>{props.task.created ? props.task.created.toLocaleString() : "no timestamp" }</div>
      </div>
      <div style={{ display: "flex", width: "90%", gap: 20 }}>
        <div style={{ width: 110 }}>changed: </div>
        <div>{props.task.changed ?props.task.changed.toLocaleString(): "no timestamp" }</div>
      </div>
      <div
        style={{
          justifySelf: "flex-end",
          marginTop: 20,
          gap: 10,
          display: "flex",
        }}
      >
        <button onClick={() => markAsDone()}> Done </button>
        <button onClick={() => markAsIgnored()}> Ignore </button>
      </div>
    </div>
  );
};
export default CardTaskComponent;
